import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { implement } from '@/api/updateUserInfo/Journal';
export default {
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '数据库SQL查询',
      page: 1,
      total: 0,
      ruleForm: {
        desc: ''
      },
      rules: {
        desc: [{
          required: true,
          message: '请输入SQL语句',
          trigger: 'blur'
        }]
      },
      allTableData: [],
      allTableKey: '',
      allTableShow: false,
      rowShow: false,
      structureShow: false,
      explainShow: false,
      selectShow: false,
      pageShow: false,
      affected: '',
      tableStructure: [],
      structures: [],
      tableExplain: [],
      explains: [],
      tableSelect: [],
      selects: []
    };
  },
  components: {
    Head: Head
  },
  mounted: function mounted() {},
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          implement({
            str: _this.ruleForm.desc
          }).then(function (res) {
            if (res.code == 0) {
              // res.data.forEach((res)=>{
              //     console.log(Object.keys(res)[0],Object.values(res)[0])

              // })
              var newDesc = _this.ruleForm.desc.replace(/(^\s*)/g, "");
              var sentence = newDesc.toLowerCase().substring(_this.ruleForm.desc.lastIndexOf(";") + 1);
              if (sentence.indexOf("select") == 0 && sentence.indexOf("limit") == -1) {
                _this.selects = Object.keys(res.data.data[0]);
                _this.tableSelect = res.data.data;
                _this.total = res.data.total;
                _this.$nextTick(function () {
                  _this.selectShow = true;
                  _this.pageShow = true;
                });
              } else if (sentence.indexOf("select") == 0 && sentence.indexOf("limit") != -1) {
                _this.selects = Object.keys(res.data[0]);
                _this.tableSelect = res.data;
                _this.$nextTick(function () {
                  _this.selectShow = true;
                  _this.pageShow = false;
                });
              } else {
                _this.$nextTick(function () {
                  _this.selectShow = false;
                  _this.pageShow = false;
                });
              }
              if (sentence.indexOf("explain") == 0) {
                _this.explains = Object.keys(res.data[0]);
                _this.tableExplain = res.data;
                _this.explainShow = true;
              } else {
                _this.explainShow = false;
              }
              if (sentence.indexOf("desc") == 0) {
                _this.structures = Object.keys(res.data[0]);
                _this.tableStructure = res.data;
                _this.structureShow = true;
              } else {
                _this.structureShow = false;
              }
              if (sentence.indexOf("show") == 0) {
                _this.allTableKey = Object.keys(res.data[0])[0];
                _this.allTableData = res.data;
                _this.allTableShow = true;
              } else {
                _this.allTableShow = false;
              }
              if (sentence.indexOf("update") == 0) {
                _this.rowShow = true;
                _this.affected = res.data[0];
                _this.$message({
                  message: '执行行数 :' + res.data,
                  type: 'success'
                });
              } else {
                _this.rowShow = false;
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.page = val;
      implement({
        str: this.ruleForm.desc,
        page: val
      }).then(function (res) {
        _this2.selects = Object.keys(res.data.data[0]);
        _this2.tableSelect = res.data.data;
        _this2.total = res.data.total;
      });
    }
  }
};